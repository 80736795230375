import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInternalFloat, setLatexStringAtIndex, initialState } from '../appSlice';
import { RootState } from '../store';
import { Text, Button, Grid, Card, Input, Divider, Toggle } from '@geist-ui/core';
import { Plus } from '@geist-ui/icons';
import Slider from '@mui/material/Slider';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import 'inter-ui/inter.css';
import MathQuillComponent from "~/components/MathQuillComponent";
import KatexComponent from "~/components/KatexComponent";
import { MathField } from 'react-mathquill';

const getMinMax = (key: string) => {
    if (key.includes('Offset')) {
        return { min: 0, max: 3 };
    }
    if (key.includes('Min') || key.includes('Max')) {
        return { min: 0, max: 1 };
    }
    if (key.includes('Steep')) {
        return { min: 0, max: 10 };
    }
    return { min: 1, max: 20 };
};

const DynamicSliders = () => {
    const dispatch = useDispatch();
    const internal_floats = useSelector((state: RootState) => state.app.internal_floats);
    const latex_strings = useSelector((state: RootState) => state.app.latex_strings);
    const slider_names = useSelector((state: RootState) => state.app.slider_names);

    const [isClient, setIsClient] = useState(false);

    const defaults: string[] = [];
    const customs: string[] = [];
    const seenSliderNames: string[] = [];

    useEffect(() => {
        setIsClient(true);
    });

    latex_strings.forEach((value, index) => {
        if (value == initialState.latex_strings[index] && index !== 0) {
            defaults.push(value);
        } else {
            customs.push(value);
        }
    });

    const handleChange = useCallback((name: any) => (event: any, newValue: any) => {
        dispatch(setInternalFloat({ name, value: newValue }));
    }, [dispatch]);

    const handleSwitchChange = useCallback((name: any) => (event: any) => {
        dispatch(setInternalFloat({ name, value: event.target.checked ? 1.0 : 0.0 }))
    }, [dispatch]);

    const handleAddClick = useCallback(() => {
        dispatch(setLatexStringAtIndex({ index: latex_strings.length, value: 'A=1' }));
    }, [dispatch, latex_strings.length]);

    const handleLatexChange = (mathField: MathField, index: number) => {
        const newLatex = mathField.latex();
        if (newLatex !== latex_strings[index]) {
            dispatch(setLatexStringAtIndex({ index, value: newLatex }));
        }
    };

    return (
        <>
            <TransitionGroup>
                {latex_strings.map((latex, index) => {
                    const isDefault = latex === initialState.latex_strings[index] && index !== 0;
                    if (!isDefault) {
                        return (
                            <CSSTransition
                                key={index}
                                timeout={300}
                                classNames="slider"
                            >
                                <div className="mathquill-container" style={{ padding: 0, margin: 0, height: "100%" }}>
                                    <Card shadow style={{
                                        padding: "0px",
                                        marginLeft: "-10px",
                                        marginRight: "-40px",
                                        marginTop: "0px",
                                        marginBottom: "5px",
                                        width: "100%",
                                        height: "100%",
                                        zIndex: 10,
                                    }}>
                                        <MathQuillComponent
                                            latex={latex}
                                            onChange={(mathField: MathField) => { handleLatexChange(mathField, index) }}
                                        />
                                        <TransitionGroup>
                                            {latex in slider_names && slider_names[latex].map((name, subIndex) => {
                                                if (!seenSliderNames.includes(name)) {
                                                    seenSliderNames.push(name);
                                                    return (
                                                        <CSSTransition
                                                            key={`${index}-${subIndex}`}
                                                            timeout={0}
                                                            classNames="slider1"
                                                        >
                                                            <div>
                                                                <Divider />
                                                                <Grid.Container justify='space-between' alignItems='center' style={{ 'paddingLeft': '10px', 'margin': 0, 'paddingTop': 10 }}>
                                                                    <Grid xs={1.5}>
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <KatexComponent math={name} />
                                                                        </span>
                                                                    </Grid>
                                                                    {
                                                                        (name.endsWith("_B")) ? <>
                                                                            <Grid xs style={{ 'marginLeft': 20 }}>
                                                                                <div style={{ margin: '0px 5px 0 5px', display: 'flex', alignItems: 'center' }}>
                                                                                    <Toggle
                                                                                        checked={Math.floor(internal_floats[name]) == 1}
                                                                                        onChange={handleSwitchChange(name)}
                                                                                        scale={1.25}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <KatexComponent math={'=' + Math.floor(internal_floats[name])} />
                                                                            </span>
                                                                        </> : <>
                                                                            <Grid xs={18}>
                                                                                <Slider
                                                                                    style={{ 'width': '100%', 'padding': 0, 'margin': 0 }}
                                                                                    value={internal_floats[name]}
                                                                                    min={-1}
                                                                                    max={4}
                                                                                    step={0.000001}
                                                                                    onChange={handleChange(name)}
                                                                                />
                                                                            </Grid>
                                                                            <Grid xs style={{ 'marginLeft': 20 }}>
                                                                                <Input
                                                                                    value={internal_floats[name].toPrecision(4)}
                                                                                    onChange={() => { handleChange(name) }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} crossOrigin={undefined}>
                                                                                </Input>
                                                                            </Grid>
                                                                        </>}
                                                                </Grid.Container>
                                                            </div>
                                                        </CSSTransition>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </TransitionGroup>
                                    </Card>
                                </div>
                            </CSSTransition >
                        );
                    }
                    return null;
                })}
            </TransitionGroup >

            <Button
                type="success"
                style={{ margin: '10px' }}
                onClick={handleAddClick}
                auto scale={2.5 / 3}
                icon={<Plus />} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}            >
                Add
            </Button>
            <div style={{ marginTop: '15px', marginBottom: '100px', marginLeft: '15px', marginRight: '15px' }}>
                <Text mt={1} style={{ fontSize: '12px', margin: 0 }}>
                    Using default values:
                </Text>
                <span style={{ fontSize: 12 }}>
                    <KatexComponent math={latex_strings.filter((value, index) => value == initialState.latex_strings[index]).join('\\text{, }\\text{ }')} />
                </span>
            </div>
        </>
    );
};

export default DynamicSliders;
