export function loadMathjs(callback: (arg0: any) => void) {
    if (typeof window === 'undefined') return;

    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjs/7.5.1/math.js';
    script.onload = () => {
        console.log('Math.js script loaded');
        const mathjs = (window as any).math;
        const { create, all } = mathjs;
        const mathInstance = create(all, {
            number: 'BigNumber',
            precision: 64,
        });

        // Additional functions and unit removal
        const mathImport = {
            lastFn: '',
            lastArgs: [],
            eigenvalues: (matrix: any) => mathjs.eigs(matrix).values,
            eigenvectors: (matrix: any) => mathjs.eigs(matrix).eigenvectors,
            comp: (a: any, b: any) => mathjs.divide(mathjs.dot(a, b), mathjs.norm(a)), // component of b along a
            proj: (a: any, b: any) =>
                mathjs.multiply(
                    mathjs.divide(a, mathjs.norm(a)),
                    mathjs.divide(mathjs.dot(a, b), mathjs.norm(a))
                ), // projection of b along a
        };

        mathInstance.import(mathImport, { override: true });

        // Disable unit parsing
        const units = (mathInstance as any).Unit.UNITS;
        Object.keys(units).forEach((unit) => {
            delete units[unit];
        });

        console.log(mathInstance.add(1, 2));

        // Call the callback function with the mathInstance
        if (callback) {
            callback(mathInstance);
        }
    };
    script.onerror = () => {
        console.error('Failed to load Math.js script');
    };
    document.head.appendChild(script);
}