//import { parseTex } from "./tes-math-parser";

import { parseTex } from "./tes-math-parser";

type EquationCode = [string, string];
type FunctionCode = [string, string];
type SliderFloats = Record<string, string[]>;
type DependencyGraph = Record<string, string[]>;

export interface ProcessMessageResult {
    functionCodes: FunctionCode[];
    codeStrings: EquationCode[];
    sliderFloats: SliderFloats;
}

// Constants
const outputSymbolStrs: string[] = ['h', 's', 'v', 'a', 'h_m', 's_m', 'v_m', 'p_r', 'p_g', 'p_b', 'r_m',
    'g_m', 'b_m', 'k', 'h_i', 'h_a', 'h_o', 'h_s', 'h_l', 's_i', 's_a',
    's_o', 's_s', 's_l', 'v_i', 'v_a', 'v_o', 'v_s', 'v_l', 'a_i', 'a_a',
    'a_o', 'a_s', 'a_l'];
const inputSymbolStrs: string[] = ['x', 'y', 'z', 'l', 'm', 't', 'l_c', 'm_c', 't_c', 'q'];
const customSymStrs: string[] = ['θ', 'r', 'e'];

const defaultExprStrs: Record<string, string> = {
    h: "x-sin(y)",
    s: "0",
    v: "0",
    a: "0",
    p_r: "0",
    p_g: "0",
    p_b: "0",
    h_i: "0",
    h_a: "1",
    h_o: "0",
    h_s: "1",
    h_l: "0",
    s_i: "0",
    s_a: "1",
    s_o: "0",
    s_s: "1",
    s_l: "0",
    v_i: "0",
    v_a: "1",
    v_o: "0",
    v_s: "1",
    v_l: "0",
    a_i: "0",
    a_a: "1",
    a_o: "0",
    a_s: "1",
    a_l: "0",
    "e_m(x,l,k)": "(1-2l)e^(-kx^2)+l",
    "t_m(x,s,m,n)": "atan(sx)(m-n)/pi+(m+n)/2",
    h_m: "t_m(h, h_s, h_a, h_i)",
    s_m: "e_m(s, s_l, s_s)",
    v_m: "e_m(v, v_l, v_s)",
    a_m: "e_m(a, a_l, a_s)",
    k: "5",
};

const customFunctions: Record<string, string> = {
    cosech: 'csch',
    arcsin: 'asin',
    arccos: 'acos',
    arctan: 'atan',
    arcsec: 'asec',
    arccsc: 'acsc',
    arccosec: 'acosec',
    arccot: 'acot',
    arcsinh: 'asinh',
    arccosh: 'acosh',
    arctanh: 'atanh',
    arcsech: 'asech',
    arccsch: 'acsch',
    arccosech: 'acosech',
};

// Utility functions
function deleteBracesAndSpaces(str: string): string {
    return str.replace(/[{}]/g, '').replace(/\s/g, '');
}

function latexToMathJs(mathjs: any, latexStr: string): string {
    try {
        const mathJSTree = parseTex(mathjs, latexStr);
        return mathJSTree.toString();
    } catch (e) {
        console.error(`Error in mathjs code asdasdgeneration: ${e}`);
        return '1.0';
    }
}

function parseLatex(mathjs: any, latexStr: string) {
    const mathJsStr = latexToMathJs(mathjs, latexStr);
    return mathjs.parse(mathJsStr);
}

function safeMathJsCode(mathjs: any, expr: string): string {
    try {
        return mathjs.compile(expr).toString();
    } catch (e) {
        console.error(`Error in mathjs code generation: ${e}`);
        return '1.0';
    }
}

function appendToOutput(mathjs: any, outputConfiguration: EquationCode[], sym: string, expr: string): void {
    outputConfiguration.push([
        deleteBracesAndSpaces(sym),
        deleteBracesAndSpaces(safeMathJsCode(mathjs, expr))
    ]);
}

function parseFunctionDefinition(lhsExpStr: string): { name: string; args: string[] } {
    const [funcName, args] = lhsExpStr.split('(');
    const argSymbols = args.slice(0, -1).split(',');
    return { name: funcName, args: argSymbols };
}

function findUndefinedSymbols(
    mathjs: any,
    lhs: string,
    expr: string,
    functionArguments: string[],
    equationStrs: Record<string, string>,
    sliderFloats: SliderFloats,
    dependencyGraph: DependencyGraph
): void {
    const freeSyms = mathjs.parse(expr).filter((node: { type: string; }) => node.type === 'SymbolNode').map((node: any) => (node.name));
    dependencyGraph[lhs] = freeSyms;

    for (const sym of freeSyms) {
        if (!Object.prototype.hasOwnProperty.call(equationStrs, sym) &&
            !customSymStrs.includes(sym) &&
            !inputSymbolStrs.includes(sym) &&
            !outputSymbolStrs.includes(sym) &&
            !functionArguments.includes(sym) &&
            !['pi', 'theta'].includes(sym)) {
            if (!sliderFloats[lhs]) {
                sliderFloats[lhs] = [];
            }
            sliderFloats[lhs].push(deleteBracesAndSpaces(sym));
        }
    }
}

export function processMessage(mathjs: any, data: string[]): ProcessMessageResult | null{
    if (!mathjs) {return null;}

    const equationCodes: EquationCode[] = [];
    const functionCodes: FunctionCode[] = [];
    const equationStrs: Record<string, string> = {};
    const customRhs: Record<string, string> = {};
    const sliderFloats: SliderFloats = {};
    const undefinedSliderFloats: SliderFloats = {};
    const dependencyGraph: DependencyGraph = {};

    for (const equation of data) {
        try {
            if (equation.includes('\\left\\{') && equation.includes('\\right\\}')) {
                // Process piecewise functions
                const [lhs, rhs] = equation.replace(/\s/g, '').split('=');
                const cleanLhs = deleteBracesAndSpaces(lhs).replace(/\\/g, '').replace(/left|right/g, '');
                const clauses = rhs.slice(6, -7).split(',');

                let clauseString = '';
                const isFunction = cleanLhs.includes('(') && cleanLhs.includes(')');
                const functionArguments = isFunction ? parseFunctionDefinition(cleanLhs).args : [];

                for (const clause of clauses) {
                    const [condition, returnVal] = clause.split(':').map((str) => parseLatex(mathjs, str));
                    findUndefinedSymbols(mathjs, cleanLhs, condition.toString(), functionArguments, equationStrs, sliderFloats, dependencyGraph);
                    findUndefinedSymbols(mathjs, cleanLhs, returnVal.toString(), functionArguments, equationStrs, sliderFloats, dependencyGraph);
                    clauseString += `${safeMathJsCode(mathjs, condition.toString())} ? ${safeMathJsCode(mathjs, returnVal.toString())} : `;
                }
                clauseString += '0';

                equationStrs[cleanLhs] = clauseString;

                if (isFunction) {
                    functionCodes.push([cleanLhs, clauseString]);
                } else {
                    equationCodes.push([cleanLhs, clauseString]);
                }
            } else {
                // Process regular equations
                console.log(1)
                const expr = parseLatex(mathjs, equation);
                console.log(2)
                if (expr.type !== 'AssignmentNode') {
                    console.log(expr.type, expr)
                    throw new Error('Expected an assignment node');
                }
                const lhsExpStr = expr.object.toString();
                const rhsExpStr = expr.value.toString();

                if (lhsExpStr.includes('(') && lhsExpStr.includes(')')) {
                    const func = parseFunctionDefinition(deleteBracesAndSpaces(lhsExpStr));
                    functionCodes.push([`${func.name}(${func.args.join(',')})`, safeMathJsCode(mathjs, rhsExpStr)]);
                } else {
                    equationStrs[lhsExpStr] = rhsExpStr;
                }

                findUndefinedSymbols(mathjs, lhsExpStr, rhsExpStr, [], equationStrs, sliderFloats, dependencyGraph);
            }
        } catch (e) {
            console.error(`Error parsing: '${equation}' ${e}`);
            throw e;
        }
    }

    // Process custom right-hand sides
    for (const [lhs, rhs] of Object.entries(equationStrs)) {
        const cleanLhs = deleteBracesAndSpaces(lhs).replace(/\\/g, '').replace(/left|right/g, '');
        if (Object.prototype.hasOwnProperty.call(defaultExprStrs, cleanLhs) && rhs !== defaultExprStrs[cleanLhs]) {
            customRhs[cleanLhs] = rhs;
        }
    }

    // Generate final equation codes
    for (const [lhs, rhs] of Object.entries(equationStrs)) {
        if (!Object.prototype.hasOwnProperty.call(defaultExprStrs, lhs) ||
            (customRhs[lhs] === rhs)) {
            appendToOutput(mathjs, equationCodes, lhs, rhs);
        }
    }

    // Process undefined slider floats
    for (const [expr, list] of Object.entries(sliderFloats)) {
        for (const name of list) {
            if (!Object.prototype.hasOwnProperty.call(equationStrs, name)) {
                if (!undefinedSliderFloats[expr]) {
                    undefinedSliderFloats[expr] = [];
                }
                undefinedSliderFloats[expr].push(deleteBracesAndSpaces(name));
            }
        }
    }

    // Topological sort for dependency resolution
    const visited: Record<string, string> = {};
    const outputOrder: string[] = [];

    function visit(node: string): void {
        if (visited[node] === 'visiting') {
            console.error("Circular dependency detected!");
            return;
        }
        if (!visited[node]) {
            visited[node] = 'visiting';
            const dependencies = dependencyGraph[node] || [];
            for (const dep of dependencies) {
                visit(dep);
            }
            visited[node] = 'visited';
            if (Object.prototype.hasOwnProperty.call(equationStrs, node)) {
                outputOrder.push(node);
            }
        }
    }

    const leftHandSides = Object.keys(dependencyGraph);
    for (const node of leftHandSides) {
        if (!visited[node]) {
            visit(deleteBracesAndSpaces(node));
        }
    }

    console.log('Output order:', outputOrder);

    return {
        functionCodes,
        codeStrings: equationCodes,
        sliderFloats: undefinedSliderFloats
    };
}