import React, { useState, useEffect, FunctionComponent } from 'react';
import { Text } from '@geist-ui/core';
import { EditableMathField, EditableMathFieldProps, MathField } from 'react-mathquill';

interface MathQuillComponentProps {
    latex: string;
    onChange: (mathField: MathField) => void;
}

// Lazy load EditableMathField on the client-side
const MathQuillComponent: FunctionComponent<MathQuillComponentProps> = (props) => {
  const [EditableMathField, setEditableMathField] = useState<null | FunctionComponent<EditableMathFieldProps>>(null);

  useEffect(() => {
    // Dynamically import the MathQuill component only in the browser
    import('react-mathquill').then(({ EditableMathField }) => {
      setEditableMathField(() => EditableMathField);
    });
  }, []);

  // Render nothing on the server or before MathQuill is available
  if (!EditableMathField) {
    return <Text>Load</Text>;
  }

  return (
    <EditableMathField
      style={{ width: "100%", height: "100%" }}
      config={{
        autoCommands: 'pi theta',
        autoOperatorNames: 'sinh cosh tanh sech sin cos tan sec csc cosec cot ' +
          'csch cosech coth arcsin arccos arctan arcsec arccsc arccosec arccot arcsinh ' +
          'arccosh arctanh arcsech arccsch arccosech arccoth asin acos atan asec acsc acosec ' +
          'acot asinh acosh atanh asech acsch acosech acoth mod abs log ln nsin ncos ntan',
      }}
      latex={props.latex}
      onChange={props.onChange}
    />
  );
};

export default MathQuillComponent;
