import type { MetaFunction } from "@remix-run/node";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../mathquill.css';
import { AppDispatch, RootState } from '../store';
import {
  setCodeStrings,
  setParticlesEnabled,
  setFunctionStrings,
  setTrack,
  addNewInternalFloat,
  setSliderFloats,
  setSpotifyToken,
  setShowBars,
  setEnabled,
  toggleDarkTheme,
  removeInternalFloatsNotInList,
} from '../appSlice';
import { GeistProvider, CssBaseline, Toggle, Button, Themes } from '@geist-ui/core';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Sun, Moon } from '@geist-ui/icons';
import DynamicSliders from '../components/DynamicSliders';
import Titlebar from "~/components/Titlebar";
import { createAsyncThunk } from "@reduxjs/toolkit";
import WebGLCanvas from "~/components/WebGLCanvas";
import { processMessage } from "~/mathProcessor";
import { loadMathjs } from "~/contexts/MathContext";

export const meta: MetaFunction = () => {
  return [
    { title: "Aetherscope" },
    { name: "description", content: "Dive into a trance of mathematics and music." },
  ];
};

export const processLatexStrings = createAsyncThunk(
  'latex/processStrings',
  async (latexStrings: string[], { dispatch }) => {
    console.log(3234234)
    loadMathjs((mathInstance) => {
      console.log(4, mathInstance)
      const data = processMessage(mathInstance, latexStrings);

      console.log(3, data)

      if (!data) { return; }

      const foundFloats: string[] = [];

      if (data.sliderFloats && Object.keys(data.sliderFloats).length > 0) {
        dispatch(setSliderFloats(data.sliderFloats));
      }

      for (const equation in data.sliderFloats) {
        if (Object.prototype.hasOwnProperty.call(data.sliderFloats, equation)) {
          for (const sliderFloat of data.sliderFloats[equation]) {
            dispatch(addNewInternalFloat(sliderFloat));
            foundFloats.push(sliderFloat);
          }
        }
      }

      dispatch(removeInternalFloatsNotInList(foundFloats));
      dispatch(setCodeStrings(data.codeStrings));
      dispatch(setFunctionStrings(data.functionCodes));
    });
  }
);

export default function Index() {
  const dispatch = useDispatch<AppDispatch>();
  const { enabled, particlesEnabled, latex_strings, track, spotify_token, isDarkTheme, showBars } = useSelector((state: RootState) => state.app);

  const [monitorSize, setMonitorSize] = useState({ width: 0, height: 0 });
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    const updateMonitorSize = () => {
      setMonitorSize({
        width: window.screen.width,
        height: window.screen.height
      });
    };

    window.addEventListener('resize', updateMonitorSize);
    updateMonitorSize();

    return () => {
      window.removeEventListener('resize', updateMonitorSize);
    };
  }, []);

  useEffect(() => {
    dispatch(processLatexStrings(latex_strings));
  }, [latex_strings, isClient]);

  const handleEnabledChange = useCallback((event: any) => {
    dispatch(setEnabled(event.target.checked));
  }, [dispatch]);

  const handleParticlesEnabledChange = useCallback((event: any) => {
    dispatch(setParticlesEnabled(event.target.checked));
  }, [dispatch]);

  const handleBarSpectrumChange = useCallback((event: any) => {
    dispatch(setShowBars(event.target.checked));
  }, [dispatch]);

  const handleThemeChange = useCallback(() => {
    dispatch(toggleDarkTheme());
  }, [dispatch]);

  const SpotifyAuthButton = () => {
    const handleClick = () => {
      if (typeof window !== 'undefined') {
        window.location.href = 'http://localhost:5000/login';
      }
    };

    return (
      <Button onClick={handleClick} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Authorize Spotify</Button>
    );
  };

  function formatArtists(track: any): string | undefined {
    if (!track?.['item']?.['artists']) {
      return undefined;
    }

    const artists = track['item']['artists'].map((artist: any) => artist['name']);
    let chars = 0;
    const trimmedArtists: string[] = [];

    for (const artist of artists) {
      trimmedArtists.push(artist);
      chars += artist.length;

      if (chars > 14) {
        break;
      }
    }

    if (trimmedArtists.length === 0) return '';

    if (trimmedArtists.length === 1) return trimmedArtists[0];

    return `${trimmedArtists.slice(0, -1).join(', ')} & ${trimmedArtists[trimmedArtists.length - 1]}`;
  }

  const lightTheme = Themes.createFromLight({ type: 'light1' });
  const darkTheme = Themes.createFromDark({ type: 'dark1' });

  return (
    <GeistProvider themes={[lightTheme, darkTheme]} themeType={isDarkTheme ? 'dark1' : 'light1'}>
      <CssBaseline />

      <PanelGroup direction="horizontal" style={{ height: '100%', margin: 0, padding: 0 }}>
        <Panel defaultSize={30} minSize={0} style={{ margin: 0, padding: 0 }}>
          <div style={{ padding: 0, height: '100vh', scrollBehavior: "smooth", overflowX: "hidden", overflowY: "auto" }}>
            <div style={{
              background: 'linear-gradient(to right,  #303030, #383838, #454545)',
              width: '100%',
              padding: '0px 0',
              textAlign: 'center'
            }}>
              <Titlebar />
            </div>
            <div style={{ padding: 0, margin: 0, flex: 1, }}>
              <div style={{ margin: '20px 5px 0 5px', display: 'flex', alignItems: 'center' }}>
                <SpotifyAuthButton />
              </div>
              <div style={{ margin: '20px 5px 0 5px', display: 'flex', alignItems: 'center' }}>
                <Toggle
                  checked={enabled}
                  onChange={handleEnabledChange}
                  scale={1.25}
                />
                <span style={{ marginLeft: '8px' }}>Enable</span>
              </div>
              <div style={{ margin: '20px 5px 0 5px', display: 'flex', alignItems: 'center' }}>
                <Toggle
                  checked={particlesEnabled}
                  onChange={handleParticlesEnabledChange}
                  scale={1.25}
                />
                <span style={{ marginLeft: '8px' }}>Enable Particles</span>
              </div>
              <div style={{ margin: '20px 5px 0 5px', display: 'flex', alignItems: 'center' }}>
                <Toggle
                  checked={showBars}
                  onChange={handleBarSpectrumChange}
                  scale={1.25}
                />
                <span style={{ marginLeft: '8px' }}>Show Bar Spectrum</span>
              </div>
              <div style={{ margin: 0, paddingTop: '10px' }}>
                <DynamicSliders />
              </div>
            </div>
            <Button
              aria-label="toggle theme" auto scale={2 / 3} px={0.6}
              iconRight={isDarkTheme ? <Moon /> : <Sun />}
              style={{
                position: 'absolute',
                bottom: '16px',
                left: '16px',
                fontSize: '12px',
                padding: '4px',
                width: '40px',
                height: '40px',
              }}
              onClick={handleThemeChange} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </div>
        </Panel>
        <PanelResizeHandle style={{ margin: 0, padding: 0 }} />
        <Panel defaultSize={70}
          style={{
            height: '100vh',
            boxShadow: '-5px 10px 20px rgba(0, 0, 0, 0.4)',
            zIndex: 1,
            margin: 0,
            padding: 0
          }}
        >
          <div style={{ backgroundColor: "#343434", height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, padding: 0 }}>
            {isClient && WebGLCanvas && (
              <div style={{ position: 'relative', width: monitorSize.width, height: monitorSize.height }}>
                <WebGLCanvas
                  width={monitorSize.width}
                  height={monitorSize.height}
                />
                {showBars && (
                  <>
                    <span
                      style={{
                        position: 'relative',
                        bottom: '37%',
                        left: '22%',
                        fontWeight: '400',
                        color: '#ffffff',
                        zIndex: 1,
                        pointerEvents: 'none',
                        display: 'block',
                        textShadow: '0px 3px 5px rgba(0, 0, 0, 0.7)',
                      }}
                    >
                      <span
                        style={{
                          display: 'block',
                          fontWeight: '800',
                          fontSize: '70px',
                        }}
                      >
                        {formatArtists(track)?.toUpperCase() ?? ''}
                      </span>
                      <span
                        style={{
                          display: 'block',
                          fontWeight: '400',
                          fontSize: '50px',
                          marginTop: '-1%'
                        }}
                      >
                        {track?.['item'] ? track['item']['name'].toUpperCase() : ''}
                      </span>
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </Panel>
      </PanelGroup>
      <div id="resizable">
        <div className="resize-handle bottom-right"></div>
      </div>
    </GeistProvider>
  );
}
