import React from 'react';
import { Minus, Maximize, X } from '@geist-ui/react-icons';
import { Grid, Image, Button } from '@geist-ui/core';

const Titlebar: React.FC = () => {

    return (
        <Grid.Container style={{ margin: 0, padding: 0 }} gap={0.5}>
            <Grid xs={7}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                        padding: '0 10px',
                        gap: '10px',
                    }}
                >
                </div>
            </Grid>
            <Grid xs={17}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%',
                        height: '100%',
                        paddingRight: 20,
                        margin: 0,
                    }}
                >
                    <img alt="" src="logo.png" style={{ height: "50px" }} />
                </div>
            </Grid>
        </Grid.Container>
    );
};

export default Titlebar;
