import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { nonVarFrameKeys } from '../appSlice';
import { RootState } from '../store';
import { Console, log } from 'console';

interface CanvasComponentProps {
    width: number;
    height: number;
}

const WebGLCanvas: React.FC<CanvasComponentProps> = ({
    width,
    height,
}) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const programRef = useRef<WebGLProgram | null>(null);
    const vertexBufferRef = useRef<WebGLBuffer | null>(null);
    const animationFrameRef = useRef<number | null>(null);
    const { enabled, particlesEnabled, showBars, internal_floats, slider_names, function_codes, code_strings } = useSelector((state: RootState) => state.app);
    const frameRef = useRef<number>(0);
    const [shouldRender, setShouldRender] = useState(enabled);
    const particlesEnabledRef = useRef(particlesEnabled);

    useEffect(() => {
        particlesEnabledRef.current = particlesEnabled;
    }, [particlesEnabled]);

    useEffect(() => {
        setShouldRender(enabled);
    }, [enabled]);

    const render = () => {
        const frame = (window as any).frameData;
        const canvas = canvasRef.current;
        const gl = canvas?.getContext('webgl', { alpha: true }) as WebGLRenderingContext | null;

        if (!gl || !programRef.current || !vertexBufferRef.current) {
            return;
        }

        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

        const shaderProgram = programRef.current;
        const vertexBuffer = vertexBufferRef.current;

        const vertexPositionAttribute = gl.getAttribLocation(
            shaderProgram,
            'aVertexPosition'
        );

        for (const key in internal_floats) {
            if (Object.prototype.hasOwnProperty.call(internal_floats, key)) {
                const value = internal_floats[key];
                const uSym = gl.getUniformLocation(shaderProgram, key);

                if (uSym) {
                    gl.uniform1f(uSym, value);
                }
            }
        }

        const uFrame = gl.getUniformLocation(shaderProgram, 'uFrame');
        const uTime = gl.getUniformLocation(shaderProgram, 'uTime');
        const enableParticles = gl.getUniformLocation(shaderProgram, 'enableParticles');
        const uResolution = gl.getUniformLocation(shaderProgram, 'uResolution');

        for (const key in frame) {
            if (Object.prototype.hasOwnProperty.call(frame, key)) {
                if (key == 'barLevels') {
                    const uBarsLocation = gl.getUniformLocation(shaderProgram, "uBars");
                    gl.uniform1fv(uBarsLocation, frame[key]);
                } else if (!(key in nonVarFrameKeys)) {
                    const uSym = gl.getUniformLocation(shaderProgram, key);
                    gl.uniform1f(uSym, frame[key]);
                }
            }
        }

        gl.clear(gl.COLOR_BUFFER_BIT);
        gl.useProgram(shaderProgram);
        gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
        gl.vertexAttribPointer(vertexPositionAttribute, 2, gl.FLOAT, false, 0, 0);
        gl.enableVertexAttribArray(vertexPositionAttribute);
        gl.uniform1f(uFrame, frameRef.current * 0.1);
        gl.uniform1i(enableParticles, particlesEnabledRef.current ? 1 : 0);
        gl.uniform1f(uTime, frame['l_c'] * 0.0000004);
        gl.uniform2f(uResolution, canvas!.width, canvas!.height);
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
    };

    useEffect(() => {
        if (!shouldRender) return;

        const canvas = canvasRef.current;

        if (!canvas) {
            console.error('Canvas is not available.');
            return;
        }

        let gl = canvas.getContext('webgl') as WebGLRenderingContext | null;

        if (!gl) {
            console.error('WebGL not supported, falling back on experimental-webgl');
            gl = canvas.getContext('experimental-webgl') as WebGLRenderingContext | null;
        }

        if (!gl) {
            alert('Your browser does not support WebGL');
            return;
        }

        const vertexShaderSource = `
            attribute vec4 aVertexPosition;
            void main(void) {
                gl_Position = aVertexPosition;
            }
        `;

        const generateShaderSource = () => {
            const frame = (window as any).frameData;
            const showBarsAndCan: boolean = (frame?.barLevels !== undefined && frame?.barLevels !== null) && showBars;
            let barNum = 3;

            if (showBarsAndCan) {
                barNum = frame.barLevels.length;
            }

            const uniformsString = frame ? Object.keys(frame)
                .filter(key => !(key in nonVarFrameKeys))
                .map(key => `uniform float ${key};`)
                .join('\n') : '';

            const barScale = '5.0';

            const fragmentShaderSource = `
            precision mediump float;
            #define TWO_PI 6.283185

            #define ANIMATION_SPEED 1.5
            #define MOVEMENT_SPEED 1.0
            #define MOVEMENT_DIRECTION vec2(0.7, -1.0)

            #define PARTICLE_SIZE 0.005

            #define PARTICLE_SCALE (vec2(0.9, 0.9))
            #define PARTICLE_SCALE_VAR (vec2(0.25, 0.2))

            #define PARTICLE_BLOOM_SCALE (vec2(1.0, 1.0))
            #define PARTICLE_BLOOM_SCALE_VAR (vec2(0.3, 0.1))

            #define SPARK_COLOR vec3(1.0, 1.0, 1.0) * 1.5
            #define BLOOM_COLOR vec3(1.0, 1.0, 1.0) * 0.8
            #define SMOKE_COLOR vec4(1.0, 1.0, 1.0, 1.0) * 0.8

            #define SIZE_MOD 1.2
            #define ALPHA_MOD 0.9
            #define LAYERS_COUNT 40
            const float PI = 3.14159265358979323846;
            const float pi = PI;
            const float M_PI_2 = 1.57079633;
            const float M_PI = PI;
            const float M_SQRT2 = 1.4142135623730951;
            const float M_PI_4 = 0.7853981633974483;
            const float e = 2.718281828459045;
            const float M_E = e;
            const float u_noiseStrength = 2.0;  // Global noise strength
            uniform bool enableParticles;
            uniform float uFrame;
            uniform vec2 uResolution;
            bool showBars = ${showBarsAndCan};
            uniform float uBars[${barNum}];
            uniform float uTime;
            float hash1_2(in vec2 x)
            {
                return fract(sin(dot(x, vec2(52.127, 61.2871))) * 521.582);   
            }

            vec2 hash2_2(in vec2 x)
            {
                return fract(sin(x * mat2(20.52, 24.1994, 70.291, 80.171)) * 492.194);
            }

            //Simple interpolated noise
            vec2 noise2_2(vec2 uv)
            {
                //vec2 f = fract(uv);
                vec2 f = smoothstep(0.0, 1.0, fract(uv));
                
                vec2 uv00 = floor(uv);
                vec2 uv01 = uv00 + vec2(0,1);
                vec2 uv10 = uv00 + vec2(1,0);
                vec2 uv11 = uv00 + 1.0;
                vec2 v00 = hash2_2(uv00);
                vec2 v01 = hash2_2(uv01);
                vec2 v10 = hash2_2(uv10);
                vec2 v11 = hash2_2(uv11);
                
                vec2 v0 = mix(v00, v01, f.y);
                vec2 v1 = mix(v10, v11, f.y);
                vec2 v = mix(v0, v1, f.x);
                
                return v;
            }

            //Simple interpolated noise
            float noise1_2(in vec2 uv)
            {
                vec2 f = fract(uv);
                //vec2 f = smoothstep(0.0, 1.0, fract(uv));
                
                vec2 uv00 = floor(uv);
                vec2 uv01 = uv00 + vec2(0,1);
                vec2 uv10 = uv00 + vec2(1,0);
                vec2 uv11 = uv00 + 1.0;
                
                float v00 = hash1_2(uv00);
                float v01 = hash1_2(uv01);
                float v10 = hash1_2(uv10);
                float v11 = hash1_2(uv11);
                
                float v0 = mix(v00, v01, f.y);
                float v1 = mix(v10, v11, f.y);
                float v = mix(v0, v1, f.x);
                
                return v;
            }

            float layeredNoise1_2(in vec2 uv, in float sizeMod, in float alphaMod, in int layers, in float animation)
            {
                float noise = 0.0;
                float alpha = 1.0;
                float size = 1.0;
                vec2 offset;
                for (int i = 0; i < LAYERS_COUNT; i++)
                {
                    if (i > layers) break;
                    offset += hash2_2(vec2(alpha, size)) * 10.0;
                    
                    //Adding noise with movement
                    noise += noise1_2(uv * size + uTime * animation * 8.0 * MOVEMENT_DIRECTION * MOVEMENT_SPEED + offset) * alpha;
                    alpha *= alphaMod;
                    size *= sizeMod;
                }
                
                noise *= (1.0 - alphaMod)/(1.0 - pow(alphaMod, float(layers)));
                return noise;
            }

            //Rotates point around 0,0
            vec2 rotate(in vec2 point, in float deg)
            {
                float s = sin(deg);
                float c = cos(deg);
                return mat2(s, c, -c, s) * point;
            }

            //Cell center from point on the grid
            vec2 voronoiPointFromRoot(in vec2 root, in float deg)
            {
                vec2 point = hash2_2(root) - 0.5;
                float s = sin(deg);
                float c = cos(deg);
                point = mat2(s, c, -c, s) * point * 0.66;
                point += root + 0.5;
                return point;
            }

            //Voronoi cell point rotation degrees
            float degFromRootUV(in vec2 uv)
            {
                return uTime * ANIMATION_SPEED * (hash1_2(uv) - 0.5) * 2.0;   
            }

            vec2 randomAround2_2(in vec2 point, in vec2 range, in vec2 uv)
            {
                return point + (hash2_2(uv) - 0.5) * range;
            }


            vec3 fireParticles(in vec2 uv, in vec2 originalUV)
            {
                vec3 particles = vec3(0.0);
                vec2 rootUV = floor(uv);
                float deg = degFromRootUV(rootUV);
                vec2 pointUV = voronoiPointFromRoot(rootUV, deg);
                float dist = 2.0;
                float distBloom = 0.0;
            
                //UV manipulation for the faster particle movement
                vec2 tempUV = uv + (noise2_2(uv * 2.0) - 0.5) * 0.1;
                tempUV += -(noise2_2(uv * 3.0 + uTime) - 0.5) * 0.07;

                //Sparks sdf
                dist = length(rotate(tempUV - pointUV, 0.7) * randomAround2_2(PARTICLE_SCALE, PARTICLE_SCALE_VAR, rootUV));
                
                //Bloom sdf
                distBloom = length(rotate(tempUV - pointUV, 0.7) * randomAround2_2(PARTICLE_BLOOM_SCALE, PARTICLE_BLOOM_SCALE_VAR, rootUV));

                //Add sparks
                particles += (1.0 - smoothstep(PARTICLE_SIZE * 0.6, PARTICLE_SIZE * 3.0, dist)) * SPARK_COLOR;
                
                //Add bloom
                particles += pow((1.0 - smoothstep(0.0, PARTICLE_SIZE * 6.0, distBloom)) * 1.0, 3.0) * BLOOM_COLOR;

                //Upper disappear curve randomization
                float border = (hash1_2(rootUV) - 0.5) * 2.0;
                float disappear = 1.0 - smoothstep(border, border + 0.5, originalUV.y);
                
                //Lower appear curve randomization
                border = (hash1_2(rootUV + 0.214) - 1.8) * 0.7;
                float appear = smoothstep(border, border + 0.4, originalUV.y);
                
                return particles * disappear * appear;
            }


            //Layering particles to imitate 3D view
            vec3 layeredParticles(in vec2 uv, in float sizeMod, in float alphaMod, in int layers, in float smoke) 
            { 
                vec3 particles = vec3(0);
                float size = 1.0;
                float alpha = 1.0;
                vec2 offset = vec2(0.0);
                vec2 noiseOffset;
                vec2 bokehUV;
                
                for (int i = 0; i < LAYERS_COUNT; i++)
                {
                    if (i > layers) break;
                    //Particle noise movement
                    noiseOffset = (noise2_2(uv * size * 2.0 + 0.5) - 0.5) * 0.15;
                    
                    //UV with applied movement
                    bokehUV = (uv * size + uTime * MOVEMENT_DIRECTION * MOVEMENT_SPEED) + offset + noiseOffset; 
                    
                    //Adding particles								if there is more smoke, remove smaller particles
                    particles += fireParticles(bokehUV, uv) * alpha * (1.0 - smoothstep(0.0, 1.0, smoke) * (float(i) / float(layers)));
                    
                    //Moving uv origin to avoid generating the same particles
                    offset += hash2_2(vec2(alpha, alpha)) * 10.0;
                    
                    alpha *= alphaMod;
                    size *= sizeMod;
                }
                
                return particles;
            }
        
            ${Object.entries(slider_names)
                    .map(([key, value]) => {
                        return value.map(body => `uniform float ${body};\n`).join('\n');
                    })
                    .join('\n\n')}
        
            ${function_codes.map(([name, body]) => `float ${name} { return ${body}; }`).join('\n')}
        
            ${uniformsString}
        
            float modFloat(float value, float modulus) {
                return value - modulus * floor(value / modulus);
            }

            float map(vec3 p) {
                vec3 n = vec3(0, 1, 0);
                float k1 = 5.0;
                float k2 = (sin(p.x * k1) + sin(p.z * k1)) * 0.8;
                float k3 = (sin(p.y * k1) + sin(p.z * k1)) * 0.8;
                float w1 = 4.0 - dot(abs(p), normalize(n)) + k2;
                float w2 = 4.0 - dot(abs(p), normalize(n.yzx)) + k3;
                float s1 = length(mod(p.xy + vec2(sin((p.z + p.x) * 2.0) * 0.3, cos((p.z + p.x) * 1.0) * 0.5), 2.0) - 1.0) - 0.2;
                float s2 = length(mod(0.5+p.yz + vec2(sin((p.z + p.x) * 2.0) * 0.3, cos((p.z + p.x) * 1.0) * 0.3), 2.0) - 1.0) - 0.2;
                return min(w1, min(w2, min(s1, s2)));
            }

            vec2 rot(vec2 p, float a) {
                return vec2(
                    p.x * cos(a) - p.y * sin(a),
                    p.x * sin(a) + p.y * cos(a));
            }
        
            vec4 renderBars(vec2 coord) {
                coord /= 2.0;
                float totalBars = ${barNum}.0;
                float gap = 0.02;  // Gap between bars
                float barWidth = ((1.0 / totalBars)) * 4.2;  // Width of each bar
                
                // Calculate the total width of all bars plus gaps
                float totalWidth = totalBars * (barWidth + gap) - gap;

                // Shift the bars to be centered
                float startX = -totalWidth / 2.0;

                float barHeight = 0.0;
                for (int i = 0; i < ${barNum}; i++) {
                    float barStartX = startX + float(i) * (barWidth + gap);  // Calculate the starting position for each bar
                    float barEndX = barStartX + barWidth;

                    if (coord.x >= barStartX && coord.x < barEndX) {
                        barHeight = uBars[i] / 50.0;
                        break;
                    }
                }

                barHeight = clamp(barHeight, 0.0, 2.3);

                // Shift the bar position to start from y = -30.0
                float bottomY = -500.0 / uResolution.y;
                if (coord.y <= barHeight + bottomY && coord.y > bottomY) {
                    return vec4(1.0, 1.0, 1.0, 1.0);  // White bar with full opacity
                } else {
                    return vec4(0.0, 1.0, 1.0, 0.0);  // Transparent color outside the bar
                }
            }
        
            vec4 renderBarShadows(vec2 coord) {
                coord /= 2.0;
                float totalBars = ${barNum}.0;
                float gap = 0.0025;  // Gap between bars
                float barWidth = ((1.0 / totalBars)) * 4.6;  // Width of each bar
                
                // Calculate the total width of all bars plus gaps
                float totalWidth = totalBars * (barWidth + gap) - gap;

                // Shift the bars to be centered
                float startX = -totalWidth / 2.0;

                float barHeight = 0.0;
                float shadowOffset = 0.005;  // Offset for the shadow
                float shadowDarkness = 0.2;  // Darkness of the shadow
                vec4 barColor = vec4(1.0, 1.0, 1.0, 1.0);  // White bar
                vec4 shadowColor = vec4(0.0, 0.0, 0.0, shadowDarkness);  // Black shadow with some transparency

                for (int i = 0; i < ${barNum}; i++) {
                    float barStartX = startX + float(i) * (barWidth + gap);  // Calculate the starting position for each bar
                    float barEndX = barStartX + barWidth;

                    if (coord.x >= barStartX && coord.x < barEndX) {
                        barHeight = uBars[i] / 50.0;
                        break;
                    }
                }

                barHeight = clamp(barHeight, 0.0, 2.3);

                // Shift the bar position to start from y = -500.0 / uResolution.y
                float bottomY = -500.0 / uResolution.y;

                // Render the shadow
                if (coord.y <= barHeight + bottomY - shadowOffset && coord.y > bottomY - shadowOffset) {
                    return shadowColor;  // Render the shadow
                }
            }

            float log(float x, float base) {
                return log(x) / log(base);
            }

            float hash(float n) {
                return fract(sin(n) * 43758.5453123);
            }

            float noise(float x) {
                float i = floor(x);
                float f = fract(x);
                float u = f * f * (3.0 - 2.0 * f); // Smooth interpolation
                return mix(hash(i), hash(i + 1.0), u);
            }

            float fbm(float x) {
                float v = 0.0;
                float a = 0.5;
                float shift = 100.0;
                for (int i = 0; i < 5; ++i) {
                    v += a * noise(x);
                    x = x * 2.0 + shift;
                    a *= 0.5;
                }
                return v;
            }

            float nsin(float x) {
                return sin(x + fbm(x) * u_noiseStrength);
            }

            float ncos(float x) {
                return cos(x + fbm(x) * u_noiseStrength);
            }

            float ntan(float x) {
                return tan(x + fbm(x) * u_noiseStrength);
            }

            float sinh(float x) {
                return (exp(x) - exp(-x)) / 2.0;
            }

            float cosh(float x) {
                return (exp(x) + exp(-x)) / 2.0;
            }

            float tanh(float x) {
                return sinh(x) / cosh(x);
            }

            float coth(float x) {
                return 1.0 / tanh(x);
            }

            float sech(float x) {
                return 1.0 / cosh(x);
            }

            float csch(float x) {
                return 1.0 / sinh(x);
            }

            float asinh(float x) {
                return log(x + sqrt(x * x + 1.0));
            }

            float acosh(float x) {
                return log(x + sqrt(x * x - 1.0));
            }

            float atanh(float x) {
                return 0.5 * log((1.0 + x) / (1.0 - x));
            }

            float acoth(float x) {
                return 0.5 * log((x + 1.0) / (x - 1.0));
            }

            float asech(float x) {
                return acosh(1.0 / x);
            }

            float acsch(float x) {
                return asinh(1.0 / x);
            }
        
            void main(void) {
                vec2 coord = (gl_FragCoord.xy / uResolution);
                vec2 uv = (2.0 * gl_FragCoord.xy - uResolution.xy) / uResolution.x;
                coord = coord * 2.0 - 1.0;
                coord.x *= uResolution.x / uResolution.y;
                ${`vec2 bar_coord = coord * ${barScale};`}
        
                // Existing shader logic
                float epsilon = 1e-6;

                ${code_strings.map(([sym, expr]) => {
                        if (sym == 'k') {
                            return `vec2 vis_coord = coord * ${expr};`;
                        }
                    }).join('\n')}
        
                float x = vis_coord.x;
                float y = vis_coord.y;
                float z = uFrame;
        
                float r = sqrt(pow(x, 2.0) + pow(y, 2.0));
                float theta = atan(y / x);
                
                ${code_strings.map(([sym, expr]) => {
                        if (sym != 'k') {
                            return `float ${sym} = ${expr};`;
                        }
                    }).join('\n')}
                
                h_m = mod(h_m + h_o, 1.0);
                s_m = clamp(s_m + s_o, 0.0, 1.0);
                v_m = clamp(v_m + v_o, 0.0, 1.0);
                a_m = clamp(a_m + a_o, 0.0, 1.0);
        
                float c = v_m * s_m;
                float x_val = c * (1.0 - abs(mod(h_m * 6.0, 2.0) - 1.0));
                float m = v_m - c;
        
                vec3 rgb;
                if (h_m < 1.0/6.0) { rgb = vec3(c, x_val, 0); }
                else if (h_m < 2.0/6.0) { rgb = vec3(x_val, c, 0); }
                else if (h_m < 3.0/6.0) { rgb = vec3(0, c, x_val); }
                else if (h_m < 4.0/6.0) { rgb = vec3(0, x_val, c); }
                else if (h_m < 5.0/6.0) { rgb = vec3(x_val, 0, c); }
                else { rgb = vec3(c, 0, x_val); }

                /*float time = 0.2 * uFrame;
                vec2 uv = ( gl_FragCoord.xy / uResolution.xy ) * 2.0 - 1.0;
                uv.x *= uResolution.x /  uResolution.y;
                vec3 dir = normalize(vec3(uv, 1.0));
                dir.xz = rot(dir.xz, time * 0.23);dir = dir.yzx;
                dir.xz = rot(dir.xz, time * 0.2);dir = dir.yzx;
                vec3 pos = vec3(0, 0, time);
                vec3 col = vec3(0.0);
                float t = 0.0;
                float tt = 0.0;
                for(int i = 0 ; i < 100; i++) {
                    tt = map(pos + dir * t);
                    if(tt < 0.001) break;
                    t += tt * 0.45;
                }
                vec3 ip = pos + dir * t;
                col = vec3(t * 0.1);
                col = sqrt(col);
                vec4 bg = vec4(0.05*t+abs(dir) * col + max(0.0, map(ip - 0.1) - tt), 1.0); //Thanks! Shane!
                bg.a = 1.0;// / (t * t * t * t);*/
                vec4 mathColor = vec4(rgb + m, a_m);
                vec4 finalColor;
                
                if (enableParticles) {
                    float vignette = 1.0 - smoothstep(0.4, 1.4, length(uv + vec2(0.0, 0.3)));
                    
                    uv *= 1.8;
                    
                    float smokeIntensity = layeredNoise1_2(uv * 10.0 + uTime * 4.0 * MOVEMENT_DIRECTION * MOVEMENT_SPEED, 1.7, 0.7, 6, 0.2);
                    smokeIntensity *= pow(1.0 - smoothstep(-1.0, 1.6, uv.y), 2.0); 
                    vec4 smoke = smokeIntensity * SMOKE_COLOR * 0.8 * vignette;
                    
                    //Cutting holes in smoke
                    smoke *= pow(layeredNoise1_2(uv * 4.0 + uTime * 0.5 * MOVEMENT_DIRECTION * MOVEMENT_SPEED, 1.8, 0.5, 3, 0.2), 2.0) * 1.5;
                    
                    vec4 particles = vec4(layeredParticles(uv, SIZE_MOD, ALPHA_MOD, LAYERS_COUNT, smokeIntensity), 1.0);
                    vec4 col = particles + smoke + SMOKE_COLOR * 0.02;
                    //col *= vignette;
                    col = smoothstep(-0.08, 1.0, col);
                    finalColor = mix(col, mathColor, mathColor.a);
                } else {
                    finalColor = mathColor;
                }
                    
                if (showBars) {  
                    vec4 bars = renderBars(bar_coord);
                    vec4 barShadows = renderBarShadows(bar_coord);
                    vec4 bars_mixed = mix(barShadows, bars, bars.a);
                    gl_FragColor = mix(finalColor, bars_mixed, bars_mixed.a);
                } else {
                    gl_FragColor = finalColor;
                }
            }
        `;

            return fragmentShaderSource;
        }

        function createShader(gl: WebGLRenderingContext, type: number, source: string) {
            const shader = gl.createShader(type);
            gl.shaderSource(shader!, source);
            gl.compileShader(shader!);
            if (!gl.getShaderParameter(shader!, gl.COMPILE_STATUS)) {
                console.error(
                    'An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader!)
                );
                console.error(generateShaderSource())
                gl.deleteShader(shader);
                return null;
            }
            return shader;
        }

        function createProgram(gl: WebGLRenderingContext, vertexShader: any, fragmentShader: any) {
            const program = gl.createProgram();
            gl.attachShader(program!, vertexShader);
            gl.attachShader(program!, fragmentShader);
            gl.linkProgram(program!);
            if (!gl.getProgramParameter(program!, gl.LINK_STATUS)) {
                console.error(
                    'Unable to initialize the shader program: ' +
                    gl.getProgramInfoLog(program!)
                );
                return null;
            }
            return program;
        }

        let vertexShader = null;
        let fragmentShader = null;

        try {
            vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
            fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, generateShaderSource());
        } catch {
            console.warn(generateShaderSource());
            console.warn('Shader creation failed, caught');
        }

        if (!vertexShader || !fragmentShader) {
            console.warn('Shader creation failed');
            return;
        }

        const shaderProgram = createProgram(gl, vertexShader, fragmentShader);

        if (!shaderProgram) {
            console.warn('Program creation failed');
            return;
        }

        programRef.current = shaderProgram;

        const vertices = new Float32Array([
            -1.0, -1.0, 1.0, -1.0, -1.0, 1.0, 1.0, 1.0,
        ]);

        const vertexBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

        vertexBufferRef.current = vertexBuffer;

        return () => {
            if (gl) {
                gl.deleteProgram(shaderProgram);
                gl.deleteBuffer(vertexBuffer);
            }
        };
    }, [shouldRender, code_strings, showBars, width, height, function_codes, slider_names]);

    useEffect(() => {
        if (!shouldRender) return;

        const animate = () => {
            render();
            frameRef.current += 1;
            animationFrameRef.current = requestAnimationFrame(animate);
        };

        animate();

        return () => {
            if (animationFrameRef.current !== null) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internal_floats, shouldRender]);

    if (shouldRender) {
        return (
            <>
           <div style={{ position: 'relative', display: 'block', width, height, overflowY: "hidden", }}>
          
                <canvas
                    ref={canvasRef}
                    width={width}
                    height={height}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                />
                {/*<img src="https://media.nomadicmatt.com/2023/tropicalislandsfeature.jpg"></img>*/}
          
            </div>
            </>
           
        );
    }

    return <div/>;
};

export default WebGLCanvas;
