import ParseError from "./ParseError";
import Token, { TokenType, typeToOperation, lexemeToType } from "./Token";

/**
 * Create the corresponding MathJS node of a Token and its children.
 * @returns A newly constructed MathJS node.
 */
function createMathJSNode(
  mathjs: any,
  token: Token,
  children: any[] = [],
): any {
  let fn = typeToOperation[token.type];
  switch (token.type) {
    case TokenType.Times:
      return new mathjs.FunctionNode("cross", children);
    case TokenType.Minus:
      // mathjs differentiates between subtraction and the unary minus
      fn = children.length === 1 ? "unaryMinus" : fn;
    // falls through
    case TokenType.Plus:
    case TokenType.Star:
    case TokenType.Frac:
    case TokenType.Slash:
      return new mathjs.OperatorNode(token.lexeme, fn, children);
    case TokenType.Caret:
      if (children.length < 2) {
        throw new ParseError("Expected two children for ^ operator", token);
      }
      // manually check for ^T as the transpose operation
      // @ts-ignore
      if (math.isSymbolNode(children[1]) && children[1].name === "T") {
        return new mathjs.FunctionNode("transpose", [children[0]]);
      }
      return new mathjs.OperatorNode(token.lexeme, fn, children);
    // mathjs built-in functions
    case TokenType.Underscore:
    case TokenType.Bar:
    case TokenType.Sqrt:
    case TokenType.Sin:
    case TokenType.Cos:
    case TokenType.Tan:
    case TokenType.Csc:
    case TokenType.Sec:
    case TokenType.Cot:
    case TokenType.Sinh:
    case TokenType.Cosh:
    case TokenType.Tanh:
    case TokenType.Arcsin:
    case TokenType.Arccos:
    case TokenType.Arctan:
    case TokenType.Log:
    case TokenType.Ln:
    case TokenType.Eigenvalues:
    case TokenType.Eigenvectors:
    case TokenType.Det:
    case TokenType.Cross:
    case TokenType.Proj:
    case TokenType.Comp:
    case TokenType.Norm:
    case TokenType.Inv:
      return new mathjs.FunctionNode(fn, children);
    case TokenType.Equals:
      return new mathjs.AssignmentNode(children[0], children[1]);
    case TokenType.Variable:
      return new mathjs.SymbolNode(token.lexeme);
    case TokenType.Number: {
      // convert string lexeme to number if posssible
      const constant = Number.isNaN(Number(token.lexeme))
        ? token.lexeme
        : +token.lexeme;
      return new mathjs.ConstantNode(constant);
    }
    case TokenType.Pi:
      return new mathjs.SymbolNode("pi");
    case TokenType.E:
      return new mathjs.SymbolNode("e");
    case TokenType.Matrix:
      return new mathjs.ArrayNode(children);
    case TokenType.T:
      return new mathjs.SymbolNode("T");
    default:
      throw new ParseError("unknown token type", token);
  }
}

// Maps each left grouping token to its corresponding right grouping token
const rightGrouping: { [key in TokenType]?: TokenType } = {
  [TokenType.Lparen]: TokenType.Rparen,
  [TokenType.Lbrace]: TokenType.Rbrace,
  [TokenType.Left]: TokenType.Right,
  [TokenType.Bar]: TokenType.Bar,
};

// Token types that are primaries or denote the start of a primary
const primaryTypes = [
  TokenType.Left,
  TokenType.Lparen,
  TokenType.Lbrace,
  TokenType.Bar,
  TokenType.Number,
  TokenType.Variable,
  TokenType.Frac,
  TokenType.Sqrt,
  TokenType.Sin,
  TokenType.Cos,
  TokenType.Tan,
  TokenType.Csc,
  TokenType.Sec,
  TokenType.Cot,
  TokenType.Arcsin,
  TokenType.Arccos,
  TokenType.Arctan,
  TokenType.Sinh,
  TokenType.Cosh,
  TokenType.Tanh,
  TokenType.Log,
  TokenType.Ln,
  TokenType.Det,
  TokenType.Pi,
  TokenType.E,
  TokenType.Begin,
  TokenType.T, // e.g. [[1,2],[3,4]]^T
  TokenType.Opname,
];

class Parser {
  tokens: Token[];

  pos: number;

  /**
   * A recursive descent parser for TeX math. The following context-free grammar is used:
   *
   * expr = term ((PLUS | MINUS) term)*
   *      | VARIABLE EQUALS expr
   *
   * term = factor ((STAR factor | primary))* //primary and factor must both not be numbers
   *
   * factor = MINUS? power
   *
   * power = primary (CARET primary)*
   *
   * primary = grouping
   *         | environnment
   *         | frac
   *         | function
   *         | NUMBER
   *         | VARIABLE
   *
   * grouping = LEFT LPAREN expr RIGHT RPAREN
   *          | LPAREN expr RPAREN
   *          | LBRACE expr RBRACE
   *          | LEFT BAR expr RIGHT BAR
   *          | BAR expr BAR
   *
   * environnment = matrix
   *
   * frac = FRAC LBRACE expr RBRACE LBRACE expr RBRACE
   *
   * matrix = BEGIN LBRACE MATRIX RBRACE ((expr)(AMP | DBLBACKSLASH))* END LBRACE MATRIX RBRACE
   *
   * function = (SQRT | SIN | COS | TAN | ...) argument
   *          | OPNAME LBRACE customfunc RBRACE argument
   *
   * argument = grouping
   *          | expr
   *
   * In general, each production is represented by one method (e.g. nextFactor(mathjs), nextPower(mathjs)...)
   *
   * @param tokens A list of Tokens to be parsed.
   */
  constructor(tokens: Token[]) {
    this.tokens = tokens;
    this.pos = 0;
  }

  /**
   * Get the type that the current token matches.
   * @param types A variable number of token types to match the current token
   *              with.
   * @returns Returns the matched token type if there is a match.
   *          Otherwise returns undefined.
   */
  match(...types: TokenType[]): TokenType | undefined {
    const { type } = this.tokens[this.pos];
    return types.indexOf(type) !== -1 ? type : undefined;
  }

  /**
   * Get the next token and advance the position in the token stream.
   * @returns Returns the next token in the token stream.
   */
  nextToken(): Token {
    return this.tokens[this.pos++];
  }

  /**
   * Get the current token in the token stream without consuming it.
   * @returns Returns the current token in the token stream.
   */
  currentToken(): Token {
    return this.tokens[this.pos];
  }

  /**
   * Get the previous token in the token stream. Returns undefined
   * if the position is at the beginning of the stream.
   * @returns Returns the previous token in the token stream.
   */
  previousToken(): Token {
    return this.tokens[this.pos - 1];
  }

  /**
   * Consume the next expression in the token stream according to the following production:
   *
   * expr => term ((PLUS | MINUS) term)*
   *       | VARIABLE EQUALS expr
   * @returns Returns the root node of an expression tree.
   */
  nextExpression(mathjs: any): any {
    let leftTerm = this.nextTerm(mathjs);
    // VARIABLE EQUALS expr
    if (this.match(TokenType.Equals)) {
      if (!mathjs.isSymbolNode(leftTerm)) {
        throw new ParseError(
          "expected variable (SymbolNode) on left hand of assignment",
          this.previousToken(),
        );
      }
      const equals = this.nextToken();
      const rightExpr = this.nextExpression(mathjs);
      return createMathJSNode(mathjs, equals, [leftTerm, rightExpr]);
    }
    // term ((PLUS | MINUS) term)*

    while (this.match(TokenType.Plus, TokenType.Minus)) {
      // build the tree with left-associativity
      const operator = this.nextToken();
      const rightTerm = this.nextTerm(mathjs);
      leftTerm = createMathJSNode(mathjs, operator, [leftTerm, rightTerm]);
    }
    return leftTerm;
  }

  /**
   * Consume the next term according to the following production:
   *
   * term => factor (((STAR | TIMES) factor) | power)*
   * @returns Returns the root node of an expression tree.
   */
  nextTerm(mathjs: any): any {
    function isNumberNode(node: any) {
      return mathjs.isConstantNode(node) && !Number.isNaN(Number(node));
    }
    let leftFactor = this.nextFactor(mathjs);
    let implicitMult = false;
    // since bmatrix is the only environnment supported, it suffices to only have
    // one token lookahead and assume that \begin is the start of a matrix.
    // However, if more environnment support is added, it would be necessary to
    // have more lookahead and ensure that the matrix begins with BEGIN LBRACE MATRIX.
    for (; ;) {
      const lookaheadType = this.match(
        TokenType.Star,
        TokenType.Times,
        TokenType.Slash,
        ...primaryTypes,
      );
      if (lookaheadType === undefined) {
        break;
      }
      let operator;
      let rightFactor;
      // multiplication between two adjacent factors is implicit as long as
      // they are not both numbers
      if (isNumberNode(leftFactor) && lookaheadType === TokenType.Number) {
        throw new ParseError(
          "multiplication is not implicit between two different" +
          "numbers: expected * or \\cdot",
          this.currentToken(),
        );
      } else if (this.match(TokenType.Star, TokenType.Times, TokenType.Slash)) {
        operator = this.nextToken();
        rightFactor = this.nextFactor(mathjs);
      } else {
        const starPos = this.pos;
        // implicit multiplication is only vaild if the right factor is not negated
        // (2x != 2-x), so we parse a power instead of a factor
        rightFactor = this.nextPower(mathjs);
        // multiplication is implicit: a multiplication (star) token needs to be created
        operator = new Token("*", TokenType.Star, starPos);
        implicitMult = true;
      }
      leftFactor = createMathJSNode(mathjs, operator, [leftFactor, rightFactor]);
      (leftFactor as any).implicit = implicitMult;
    }
    return leftFactor;
  }

  /**
   * Consume the next factor according to the following production:
   *
   * factor => MINUS? power
   * @returns The root node of an expression tree.
   */
  nextFactor(mathjs: any): any {
    // match for optional factor negation
    if (this.match(TokenType.Minus)) {
      const negate = this.nextToken();
      const primary = this.nextPower(mathjs);
      return createMathJSNode(mathjs, negate, [primary]);
    }
    return this.nextPower(mathjs);
  }

  /**
   * Consume the next power according to the following production:
   *
   * power => primary (CARET primary)*
   * @returns The root node of an expression tree.
   */
  nextPower(mathjs: any): any {
    let base = this.nextPrimary(mathjs);
    while (this.match(TokenType.Caret)) {
      const caret = this.nextToken();
      const exponent = this.nextPrimary(mathjs);
      base = createMathJSNode(mathjs, caret, [base, exponent]);
    }
    return base;
  }

  /**
   * Try to consume a token of the given type. If the next token does not match,
   * an error is thrown.
   * @param errMsg Error message associated with the error if the match fails.
   * @param tokenTypes A variable amount of token types to match.
   * @returns Returns the consumed token on successful match.
   */
  tryConsume(mathjs: any, errMsg: string, ...tokenTypes: TokenType[]): Token {
    const lookaheadType = this.match(...tokenTypes);
    if (lookaheadType === undefined) {
      throw new ParseError(errMsg, this.currentToken());
    }
    return this.nextToken();
  }

  /**
   * Consume the next primary according to the following production:
   *
   * primary => grouping
   *          | environnment
   *          | frac
   *          | function
   *          | NUMBER
   *          | VARIABLE
   *
   * @returns The root node of an expression tree.
   */
  nextPrimary(mathjs: any): any {
    const lookaheadType = this.match(...primaryTypes);
    if (lookaheadType === undefined) {
      throw new ParseError("expected primary", this.currentToken());
    }
    let primary;
    switch (lookaheadType) {
      case TokenType.Left:
      case TokenType.Lparen:
      case TokenType.Lbrace:
      case TokenType.Bar:
        // nextGrouping can return an array of children
        // (if the grouping contains comma-seperated values, e.g. for a multi-value function),
        // so for a primary, we only take the first value (or if there is just one, the only value)
        [primary] = this.nextGrouping(mathjs);
        break;
      case TokenType.Number:
      case TokenType.Variable:
      case TokenType.Pi:
      case TokenType.E:
      case TokenType.T:
        primary = createMathJSNode(mathjs, this.nextToken());
        break;
      case TokenType.Sqrt:
      case TokenType.Sin:
      case TokenType.Cos:
      case TokenType.Tan:
      case TokenType.Csc:
      case TokenType.Sec:
      case TokenType.Cot:
      case TokenType.Arcsin:
      case TokenType.Arccos:
      case TokenType.Arctan:
      case TokenType.Sinh:
      case TokenType.Cosh:
      case TokenType.Tanh:
      case TokenType.Log:
      case TokenType.Ln:
      case TokenType.Det:
        primary = this.nextUnaryFunc(mathjs);
        break;
      case TokenType.Opname:
        primary = this.nextCustomFunc(mathjs);
        break;
      case TokenType.Frac:
        primary = this.nextFrac(mathjs);
        break;
      case TokenType.Begin:
        // matrix is the only currently supported environnment: if more are added, another
        // token of lookahead would be required to know which environnment to parse
        primary = this.nextMatrix(mathjs);
        break;
      default:
        throw new ParseError(
          "unknown token encountered during parsing",
          this.nextToken(),
        );
    }
    return primary;
  }

  /**
   * Consume the next grouping according to the following production:
   *
   * grouping = LEFT LPAREN expr RIGHT RPAREN
   *          | LPAREN expr RPAREN
   *          | LBRACE expr RBRACE
   *          | LEFT BAR expr RIGHT BAR
   *          | BAR expr BAR
   *          | expr
   *
   * @returns The root node of an expression tree.
   */
  nextGrouping(mathjs: any): any[] {
    // token indicating start of grouping
    let leftRight = false; // flag indicating if grouping tokens are marked with \left and \right
    if (this.match(TokenType.Left)) {
      leftRight = true;
      this.nextToken(); // consume \left
    }
    const leftGrouping = this.tryConsume(mathjs, 
      "expected '(', '|', '{'",
      TokenType.Lparen,
      TokenType.Bar,
      TokenType.Lbrace,
    );
    let grouping = this.nextExpression(mathjs);

    if (leftGrouping.type === TokenType.Bar) {
      // grouping with bars |x| also applies a function, so we create the corresponding function
      // here
      grouping = createMathJSNode(mathjs, leftGrouping, [grouping]);
    }
    // a grouping can contain multiple children if the
    // grouping is parenthetical and the values are comma-seperated
    const children: any[] = [grouping];
    if (leftGrouping.type === TokenType.Lparen) {
      while (this.match(TokenType.Comma)) {
        this.nextToken(); // consume comma
        children.push(this.nextExpression(mathjs));
      }
    }
    if (leftRight) {
      this.tryConsume(mathjs, 
        "expected \\right to match corresponding \\left after expression",
        TokenType.Right,
      );
    }
    // look for corresponding right grouping
    this.tryConsumeRightGrouping(mathjs, leftGrouping);
    return children;
  }

  /**
   * Consume the next token corresponding to a built-in MathJS function.
   *
   * @returns The root node of an expression tree.
   */
  nextUnaryFunc(mathjs: any): any {
    const func = this.nextToken();
    const argument = this.nextArgument(mathjs);
    return createMathJSNode(mathjs, func, argument);
  }

  /**
   * Consume the next token corresponding to a user-defined function.
   *
   * customFn => OPNAME LBRACE identifier RBRACE grouping
   * @returns The root node of an expression tree.
   */
  nextCustomFunc(mathjs: any): any {
    this.nextToken(); // consume \\operatornmae
    this.tryConsume(mathjs, "expected '{' after \\operatorname", TokenType.Lbrace);
    const customFunc = this.nextToken();
    this.tryConsume(mathjs, "expected '}' after operator name", TokenType.Rbrace);
    const argument = this.nextArgument(mathjs);
    return createMathJSNode(mathjs, customFunc, argument);
  }

  /**
   * Consume the next group of arguments according to the following production:
   *
   * argument => grouping
   *           | expr
   *
   * @returns The root node of an expression tree.
   */
  nextArgument(mathjs: any): any[] {
    let argument;
    // try to match grouping e.g. (mathjs), {}, ||
    if (
      this.match(
        TokenType.Left,
        TokenType.Lparen,
        TokenType.Lbrace,
        TokenType.Bar,
      )
    ) {
      // grouping around argument e.g. \sin (x)
      argument = this.nextGrouping(mathjs);
    } else {
      // no grouping e.g. \sin x; consume the next token as the argument
      argument = [this.nextPrimary(mathjs)];
    }
    return argument;
  }

  /**
   * Consume the next fraction according to the following production:
   *
   * frac => FRAC LBRACE expr RBRACE LBRACE expr RBRACE
   *
   * @returns The root node of an expression tree.
   */
  nextFrac(mathjs: any): any {
    const frac = this.nextToken();
    this.tryConsume(mathjs, 
      "expected '{' for the numerator in \\frac",
      TokenType.Lbrace,
    );
    const numerator = this.nextExpression(mathjs);
    this.tryConsume(mathjs, 
      "expected '}' for the numerator in \\frac",
      TokenType.Rbrace,
    );
    let denominator;
    // {} is optional for the denominator of \frac
    if (this.match(TokenType.Lbrace)) {
      this.nextToken();
      denominator = this.nextExpression(mathjs);
      this.tryConsume(mathjs, 
        "expected '}' for the denominator in \\frac",
        TokenType.Rbrace,
      );
    } else {
      denominator = this.nextExpression(mathjs);
    }
    return createMathJSNode(mathjs, frac, [numerator, denominator]);
  }

  /**
   * Consume the next matrix environnment according to the following production:
   *
   * matrix => BEGIN LBRACE MATRIX RBRACE ((expr)(AMP | DBLBACKSLASH))* END LBRACE MATRIX RBRACE
   *
   * @returns The root node of an expression tree.
   */
  nextMatrix(mathjs: any): any {
    this.nextToken(); // consume \begin
    this.tryConsume(mathjs, "expected '{' after \\begin", TokenType.Lbrace);
    const matrixToken = this.tryConsume(mathjs, 
      "expected 'matrix' after '\\begin{' " +
      "(no other environnments" +
      "are supported yet)",
      TokenType.Matrix,
    );
    this.tryConsume(mathjs, "expected '}' after \\begin{matrix", TokenType.Rbrace);
    let row = [];
    const rows = [];
    // parse matrix elements
    for (; ;) {
      const element = this.nextExpression(mathjs);
      // '&' delimits columns; append 1 element to this row
      if (this.match(TokenType.Amp)) {
        this.nextToken();
        row.push(element);
      } else if (
        this.match(TokenType.Dblbackslash, TokenType.End) !== undefined
      ) {
        // '\\' delimits rows; add a new row
        const delimiter = this.nextToken();
        row.push(element);
        if (row.length === 1) {
          rows.push(element);
        } else {
          rows.push(createMathJSNode(mathjs, matrixToken, row));
        }
        row = [];
        if (delimiter.type === TokenType.End) {
          break;
        }
      } else if (this.match(TokenType.Eof)) {
        throw new ParseError(
          "unexpected EOF encountered while parsing matrix",
          this.currentToken(),
        );
      } else {
        throw new ParseError(
          "unexpected delimiter while parsing matrix",
          this.currentToken(),
        );
      }
    }
    this.tryConsume(mathjs, "expected '{' after \\end", TokenType.Lbrace);
    this.tryConsume(mathjs, 
      "expected 'matrix' after '\\end{' (no other environnments" +
      "are supported yet)",
      TokenType.Matrix,
    );
    this.tryConsume(mathjs, "expected '}' after \\end{matrix", TokenType.Rbrace);
    return createMathJSNode(mathjs, matrixToken, rows);
  }

  /**
   * Try to consume the right grouping token corresponding to the given left grouping token.
   * e.g. '(' => ')', '{' => '}'. If the token doesn't match, an error is thrown.
   *
   * @param leftGroupingToken A left grouping token.
   *
   */
  // Try to consume a right grouping character given the corresponding left grouping token
  // e.g. RPAREN for LPAREN, BAR for BAR
  tryConsumeRightGrouping(mathjs: any, leftGroupingToken: Token) {
    const rightGroupingType = rightGrouping[leftGroupingToken.type];
    // get any tokens that match with the required token type
    const expectedLexemes = Object.keys(lexemeToType)
      .filter((key) => lexemeToType[key] === rightGroupingType)
      // insert quotes (e.g. { => '{')
      .map((lexeme) => `'${lexeme}'`);
    const errMsg = `expected ${expectedLexemes.join(
      " or ",
    )} to match corresponding '${leftGroupingToken.lexeme}'`;
    this.tryConsume(mathjs, errMsg, rightGrouping[leftGroupingToken.type]!);
  }
}

/**
 * Parse an array of TeX math tokens as a MathJS expression tree.
 *
 * @param tokens An array of tokens to parse.
 *
 * @returns The root node of a MathJS expression tree.
 */
export default function parseTokens(mathjs: any, tokens: Token[]): any {
  return new Parser(tokens).nextExpression(mathjs);
}
